import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Container, EmployeeList } from './MitarbeiterList.element';

const MitarbeiterList = ({ employees, setFilteredEmployees }) => {
  const [employeeStates, setEmployeeStates] = useState({});
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const [allVisible, setAllVisible] = useState(false);

  useEffect(() => {
    const initialVisibleEmployees = [];
    setSelectedEmployeeIds(initialVisibleEmployees);
    setFilteredEmployees(initialVisibleEmployees);
  }, [employees, setFilteredEmployees]);

  const toggleEyeFilter = (employeeID) => {
    const isIgnored = selectedEmployeeIds.includes(employeeID);

    setEmployeeStates((prevStates) => {
      const updatedStates = { ...prevStates };
      updatedStates[employeeID] = !isIgnored;
      return updatedStates;
    });

    if (isIgnored) {
      const newSelectedIds = selectedEmployeeIds.filter((id) => id !== employeeID);
      setSelectedEmployeeIds(newSelectedIds);
      setFilteredEmployees(newSelectedIds);
    } else {
      const newSelectedIds = [...selectedEmployeeIds, employeeID];
      setSelectedEmployeeIds(newSelectedIds);
      setFilteredEmployees(newSelectedIds);
    }
  };

  const toggleAllEmployeesVisibility = () => {
    if (allVisible) {
      setSelectedEmployeeIds([]);
      setFilteredEmployees([]);
    } else {
      const allEmployeeIds = employees.map((e) => e.mitarbeiter_id);
      setSelectedEmployeeIds(allEmployeeIds);
      setFilteredEmployees(allEmployeeIds);
    }
    setAllVisible(!allVisible);
  };

  return (
    <Container>
   
     
        <FontAwesomeIcon
          icon={allVisible ? faEyeSlash : faEye}
          style={{ cursor: 'pointer' }}
          title="Toggle visibility for all employees"
          onClick={toggleAllEmployeesVisibility}
          aria-label="Toggle visibility for all employees"
        />
  
      <EmployeeList>
        {employees.map((employee) => (
          <li
            key={employee.mitarbeiter_id}
            style={{
              backgroundColor: employee.farbe,
              color: selectedEmployeeIds.includes(employee.mitarbeiter_id) ? '#495057' : '#dc3545',
            }}
            tabIndex="0"
            role="button"
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') toggleEyeFilter(employee.mitarbeiter_id);
            }}
            onClick={() => toggleEyeFilter(employee.mitarbeiter_id)}
          >
            <span>{employee.name}</span>
            <FontAwesomeIcon
              icon={selectedEmployeeIds.includes(employee.mitarbeiter_id) ? faEyeSlash : faEye}
              onClick={(e) => {
                e.stopPropagation();
                toggleEyeFilter(employee.mitarbeiter_id);
              }}
              style={{ cursor: 'pointer' }}
            />
          </li>
        ))}
      </EmployeeList>
    </Container>
  );
};

export default MitarbeiterList;
