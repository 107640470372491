import React, { createContext, useState, useEffect } from 'react';
import API from './axioSetup';
import {jwtDecode}from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Funktion zur Aktualisierung des Access-Tokens
  const refreshAccessToken = async () => {
    try {
      console.log('Refreshing access token...');
      const { data } = await API.post('/refresh-token');

      if (!data.accessToken) {
        throw new Error('No access token found in response');
      }

      const newAccessToken = data.accessToken;
      localStorage.setItem('access_token', newAccessToken);

      const decodedToken = jwtDecode(newAccessToken);
      setUser(decodedToken);
      setRole(decodedToken.role);

      console.log('New token expires at:', new Date(decodedToken.exp * 1000));
      scheduleTokenRefresh(decodedToken);
    } catch (error) {
      console.error('Error refreshing access token:', error);
      logout(); // Logout, wenn Token-Aktualisierung fehlschlägt
    }
  };

  // Funktion zur Planung der Token-Erneuerung
  const scheduleTokenRefresh = (decodedToken) => {
    const expiresIn = decodedToken.exp * 1000 - Date.now();
    const bufferTime = 60000; // 1 Minute Puffer vor dem Ablauf
    const refreshTime = Math.max(bufferTime, expiresIn - bufferTime);

    console.log('Token will be refreshed in:', refreshTime / 1000, 'seconds');
    setTimeout(refreshAccessToken, refreshTime);
  };

  // Initialisierung des Authentifizierungszustands
  useEffect(() => {
    const initializeAuthState = () => {
      const accessToken = localStorage.getItem('access_token');

      if (accessToken) {
        try {
          const decodedToken = jwtDecode(accessToken);
          setUser(decodedToken);
          setRole(decodedToken.role);

          scheduleTokenRefresh(decodedToken);
        } catch (error) {
          console.error('Failed to decode token:', error);
          localStorage.removeItem('access_token');
          setUser(null);
          setRole(null);
        }
      }
      setLoading(false);
    };

    initializeAuthState();

    // Cleanup-Funktion
    return () => {
      setUser(null);
      setRole(null);
    };
  }, []);

  // Login-Funktion
  const login = async (username, password) => {
    try {
      setLoading(true);
      const { data } = await API.post('/login', { username, password }, {
        timeout: 5000 // 5 Sekunden Timeout
      });

      localStorage.setItem('access_token', data.accessToken);
      localStorage.setItem('session_id', data.sessionId);

      const decodedToken = jwtDecode(data.accessToken);
      setUser(decodedToken);
      setRole(decodedToken.role);

      scheduleTokenRefresh(decodedToken);

      navigate(decodedToken.role === 'filiale' ? `/user/${decodedToken.username}` : '/login');
    } catch (error) {
      console.error('Login failed:', error);
      throw new Error('Invalid login credentials');
    } finally {
      setLoading(false);
    }
  };

  // Logout-Funktion
  const logout = async () => {
    try {
      const sessionId = localStorage.getItem('session_id');
      await API.post('/logout', { session_id: sessionId });
    } catch (error) {
      console.error('Logout API request failed:', error);
    } finally {
      localStorage.removeItem('access_token');
      localStorage.removeItem('session_id');
      localStorage.removeItem('selectedDate'); // Falls du dieses Item verwendest
      setUser(null);
      setRole(null);

      navigate('/login');
    }
  };

  return (
    <AuthContext.Provider value={{ user, role, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
