import React, { useState, useContext } from 'react';
import AuthContext from '../context/authContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {
  Container,
  Background,
  ShapeFirst,
  ShapeLast,
  Form,
  FormHeader,
  Label,
  Input,
  Button,
  InputContainer,
  DonkeyContainer,
  DonkeyFace,
  DonkeyEar,
  DonkeyEye,
  DonkeyGlasses,
  DonkeySmile,
  DonkeyFoot,
  DonkeyToe,
} from './Login.element';

const LoginComponent = () => {
  const { login } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [eyesClosed, setEyesClosed] = useState(false);
  const [eyesGold, setEyesGold] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(username, password);
      setError('');
      setEyesClosed(false); // Eyes open when login is successful
      setEyesGold(true); // Eyes turn gold when login is successful
    } catch (err) {
      setError(err.message);
      setEyesClosed(true); // Eyes close when login fails
      setEyesGold(false); // Eyes remain non-gold on error
    }
  };

  const togglePasswordVisibility = (e) => {
    e.stopPropagation();
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // Move glasses and feet with mouse
  const handleMouseMove = (e) => {
    // Move eyes
    const eyeElements = document.querySelectorAll('.donkey-eye');
    eyeElements.forEach((eye) => {
      const rect = eye.getBoundingClientRect();
      const eyeX = rect.left + rect.width / 2;
      const eyeY = rect.top + rect.height / 2;
      const angle = Math.atan2(e.clientY - eyeY, e.clientX - eyeX);
      const x = Math.cos(angle) * 8;
      const y = Math.sin(angle) * 8;
      eye.style.transform = `translate(${x}px, ${y}px)`;
    });

    // Move feet
    const footElements = document.querySelectorAll('.donkey-foot');
    footElements.forEach((foot) => {
      const rect = foot.getBoundingClientRect();
      const footX = rect.left + rect.width / 2;
      const footY = rect.top + rect.height / 2;
      const angle = Math.atan2(e.clientY - footY, e.clientX - footX);
      const x = Math.cos(angle) * 8;
      const y = Math.sin(angle) * 8;
      foot.style.transform = `translate(${x}px, ${y}px)`;
    });
  };

  return (
    <Container onMouseMove={handleMouseMove}>
      <Background>
        <ShapeFirst />
        <ShapeLast />
      </Background>

      {/* Donkey Character */}
      <DonkeyContainer>
        <DonkeyEar left />
        <DonkeyEar />
        <DonkeyFace>
          <DonkeyGlasses>
            <DonkeyEye className="donkey-eye" closed={eyesClosed} gold={eyesGold} />
            <DonkeyEye className="donkey-eye" right closed={eyesClosed} gold={eyesGold} />
          </DonkeyGlasses>
          <DonkeySmile />
        </DonkeyFace>
      </DonkeyContainer>

      <Form onSubmit={handleSubmit}>
        {/* Left Foot */}
        <DonkeyFoot className="donkey-foot" left>
          {[...Array(5)].map((_, index) => (
            <DonkeyToe key={index} />
          ))}
        </DonkeyFoot>

        <FormHeader>Gronde Sehen & Hören  Kalender</FormHeader>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <Label htmlFor="Username">Username</Label>
        <Input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />

        <Label htmlFor="password">Passwort</Label>
        <InputContainer>
          <Input
            autoComplete="off"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
     <FontAwesomeIcon
  icon={showPassword ? faEyeSlash : faEye}
  onClick={togglePasswordVisibility}
  style={{
    position: 'absolute',
    right: window.innerWidth <= 768 ? '-10px' : window.innerWidth <= 1024 ? '20px' : '30px', // Adjust right margin based on screen size
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    color: '#495057',
    fontSize: window.innerWidth <= 768 ? '1rem' : window.innerWidth <= 1024 ? '1.1rem' : '1.2rem', // Adjust font size based on screen size
  }}
/>

        </InputContainer>

        <Button type="submit">Login</Button>

        {/* Right Foot */}
        <DonkeyFoot className="donkey-foot">
          {[...Array(5)].map((_, index) => (
            <DonkeyToe key={index} />
          ))}
        </DonkeyFoot>
      </Form>
     
    </Container>
  );
};

export default LoginComponent;
