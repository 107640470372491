import styled from "styled-components";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const FormContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 700px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  max-height: 90vh;
  overflow:visible;

  @media (max-width: 768px) {
    width: 95%;
    padding: 15px;
    max-height: 85vh;
  }

  @media (max-width: 480px) {
    width: 95%;
    padding: 10px;
    max-height: 80vh;
  }
`;

export const Header = styled.div`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormHeader1 = styled.h3`
  font-size: 2rem;
  margin: 0;
  color: #333;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

export const InputEv = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px; /* Ensure font-size is at least 16px */
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 480px) {
    font-size: 16px; /* Ensure font-size is consistent on mobile */
    padding: 10px;
  }
`;


export const SelectEmployee = styled.select`
  width: 100%;
  padding: 12px;
  height: 100px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-size: 16px; /* Ensure font-size is at least 16px */
  color: #333;

  @media (max-width: 480px) {
    height: 150px;
  }
`;


export const MitarbeiterPicker = styled.div`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  background-color: #f5f5f5;
`;

export const DataContainer = styled.div`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const DataContainerDatePicker = styled.div`
  width: 100%;
  display: ${(props) => (props.showRepeatComponent ? 'none' : 'flex')};
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative; /* Ensure relative positioning */

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15px;
  }

  div {
    flex-grow: 1;
    &:first-child {
      margin-right: 10px;
    }
  }
`;

export const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  font-size: 1rem;
  transition: background-color 0.3s;
  display: ${(props) => (props.showRepeatComponent ? 'none' : 'block')};
  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 480px) {
    padding: 10px;
    font-size: 0.9rem;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  z-index: 2000; /* Increase z-index to make sure it appears above other elements */

  .react-datepicker {
    font-family: 'Segoe UI', sans-serif;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 2000; /* Increase z-index to ensure full visibility */
  }

  .react-datepicker__header {
    background-color: #943534;
    border-bottom: none;
    padding-top: 10px;
    border-radius: 8px 8px 0 0;
    z-index: 2000;
  }

  .react-datepicker__triangle {
    display: none; /* Optional, removes the small arrow */
  }


  .react-datepicker-popper {
    z-index: 2000;
  }

  @media (max-width: 768px) {
    .react-datepicker {
      width: 100%;
    }

    .react-datepicker__header {
      padding-top: 5px;
    }

    .react-datepicker__current-month {
      font-size: 1em;
    }
  }
`;
