import React, { useEffect, useContext } from 'react';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import EventForm from './EventForm';
import EventEdit from './EventEdit';
import useCalendarState from '../Hooks/useCalendarState';
import { LanguageContext } from '../context/LanguageContext';
import './calandar.css';
import { EventsContext} from '../context/EveMitContext'
import CustomToolbar from './CustomToolbar';
const CalendarWithDragAndDrop = withDragAndDrop(Calendar);
let allViews = Object.keys(Views).map((k) => Views[k]);
const locales = {
  de: deLocale,
  en: enLocale,
};

const getLocalizer = (language) => {
  const locale = locales[language];
  return dateFnsLocalizer({
    format: (date, formatStr, options) => format(date, formatStr, { ...options, locale }),
    parse: (dateStr, formatStr, options) => parse(dateStr, formatStr, new Date(), { ...options, locale }),
    startOfWeek: (date, options) => startOfWeek(date, { ...options, locale }),
    getDay: (date, options) => getDay(date, { ...options, locale }),
    locales: { [language]: locale },
  });
};

const getFormats = (language) => {
  return {
    timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'p', culture),
    eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, 'p', culture)} - ${localizer.format(end, 'p', culture)}`,
    agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, 'p', culture)} - ${localizer.format(end, 'p', culture)}`,
    dayFormat: (date, culture, localizer) => localizer.format(date, 'eeee MMMM d', culture),
    dateFormat: (date, culture, localizer) => localizer.format(date, 'dd', culture),
    monthHeaderFormat: (date, culture, localizer) => localizer.format(date, 'MMMM yyyy', culture),
    dayHeaderFormat: (date, culture, localizer) => localizer.format(date, 'eeee MMMM d', culture),
    agendaDateFormat: (date, culture, localizer) => localizer.format(date, 'eeee MMMM d', culture),
    monthFormat: (date, culture, localizer) => localizer.format(date, 'MMMM', culture),
    weekdayFormat: (date, culture, localizer) => localizer.format(date, 'eeeeee', culture),
  };
};

const messages = {
  de: {
    date: 'Datum',
    time: 'Uhrzeit',
    event: 'Ereignis',
    allDay: 'Ganztägig',
    week: 'Woche',
    work_week: 'Arbeitswoche',
    day: 'Tag',
    month: 'Monat',
    previous: 'Zurück',
    next: 'Weiter',
    yesterday: 'Gestern',
    tomorrow: 'Morgen',
    today: 'Heute',
    agenda: 'Agenda',
    noEventsInRange: 'Keine Ereignisse in diesem Zeitraum.',
    showMore: (total) => `+ Zeige mehr (${total})`,
  },
  en: {
    date: 'Date',
    time: 'Time',
    event: 'Event',
    allDay: 'All day',
    week: 'Week',
    work_week: 'Work week',
    day: 'Day',
    month: 'Month',
    previous: 'Back',
    next: 'Next',
    yesterday: 'Yesterday',
    tomorrow: 'Tomorrow',
    today: 'Today',
    agenda: 'Agenda',
    noEventsInRange: 'No events in this range.',
    showMore: (total) => `+ Show more (${total})`,
  },
};

function CalendarKomponent({ selectedDate, setSelectedDate }) {
  const {
    calendarRef,
    showEventForm,
    setShowEventForm,
    showEventEdit,
    setShowEventEdit,
    hoveredEvent,
    newEvent,
    setNewEvent,
    employees,
    handleSelectSlot,
    handleEventHover,
    moveEvent,
    resizeEvent,
    eventPropGetter,
    tooltipAccessor,
    view,
    setView,
  } = useCalendarState();

  const { allEvents, setAllEvents } = useContext(EventsContext);
  const { language } = useContext(LanguageContext);
  const localizer = getLocalizer(language);
  const formats = getFormats(language);
  const calendarMessages = messages[language];

  useEffect(() => {
    const savedDate = localStorage.getItem('selectedDate');
    if (savedDate) {
      setSelectedDate(new Date(savedDate));
    }
  }, [setSelectedDate]);

  useEffect(() => {
    if (selectedDate) {
      localStorage.setItem('selectedDate', selectedDate.toISOString());
    }
  }, [selectedDate]);

  useEffect(() => {
    localStorage.setItem('calendarView', view);
  }, [view]);

  useEffect(() => {
    const logEventDetails = () => {
      document.querySelectorAll('.rbc-day-slot').forEach((slot) => {
        const events = Array.from(slot.querySelectorAll('.rbc-event'));

        // Sort events based on their length (end time - start time)
        const sortedEvents = events.sort((a, b) => {
          const aStart = new Date(a.dataset.start);
          const aEnd = new Date(a.dataset.end);
          const bStart = new Date(b.dataset.start);
          const bEnd = new Date(b.dataset.end);

          const aDuration = aEnd - aStart;
          const bDuration = bEnd - bStart;

          // Longer events are sorted to the front (higher priority)
          return bDuration - aDuration;
        });

        // Apply sorting and set the 'order' property
        sortedEvents.forEach((event, index) => {
          event.style.setProperty('--event-order', index);
        });

        // Update slot if there are multiple events
        const eventCount = events.length;
        if (eventCount > 1) {
          slot.setAttribute('data-multiple-events', 'true');
          slot.style.setProperty('--event-count', eventCount);
        } else {
          slot.removeAttribute('data-multiple-events');
          slot.style.removeProperty('--event-count');
        }
      });
    };

    logEventDetails();
    window.addEventListener('resize', logEventDetails);

    return () => {
      window.removeEventListener('resize', logEventDetails);
    };
  }, [view]);

  // Define the onShowMore function without useCallback
  const onShowMore = (events, date) => {
 
    // Or use a custom message if `buildMessage` is available
    // window.alert(buildMessage(events, date));
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <CalendarWithDragAndDrop
          ref={calendarRef}
          style={{ height: '100%', width: '100%', minHeight: '800px' }}
          localizer={localizer}
          events={allEvents}
          formats={formats}
          messages={calendarMessages}
          tooltipAccessor={tooltipAccessor}
          startAccessor={(event) => new Date(event.start_datetime)}
          endAccessor={(event) => new Date(event.end_datetime)}
          step={30}
          timeslots={1}
          views={allViews}
          view={view}
          onView={setView}
          onSelectSlot={handleSelectSlot}
          showMultiDayTimes={false}
          resizable
          onEventDrop={moveEvent}
          onEventResize={resizeEvent}
          selectable
          draggable
          eventPropGetter={eventPropGetter}
          min={new Date('2023-07-20T07:00:00')}
          max={new Date('2023-07-20T21:00:00')}
          onSelectEvent={handleEventHover}
          date={selectedDate}
          onNavigate={setSelectedDate}
          dayLayoutAlgorithm={'no-overlap'}
          popup
          onShowMore={onShowMore} // Add the onShowMore prop here
          components={{
            toolbar: (props) => (
              <CustomToolbar
                {...props}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                view={view}
                messages={calendarMessages} // Pass the translations
              />
            ),
          }}
        />
      
      </DndProvider>

      {showEventForm && (
        <EventForm
          setShowEventForm={setShowEventForm}
          newEvent={newEvent}
          setNewEvent={setNewEvent}
        />
      )}
      {showEventEdit && hoveredEvent && (
        <EventEdit
          hoveredEvent={hoveredEvent}
          employees={employees}
          setShowEventEdit={setShowEventEdit}
        />
      )}
    </>
  );
}

export default CalendarKomponent;
