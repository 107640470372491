import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function CustomToolbar({ label, onNavigate, onView, view, selectedDate, setSelectedDate, messages }) {
  // Define the available views and their translation labels
  const availableViews = {
    month: messages.month || 'Month',
    week: messages.week || 'Week',
    work_week: messages.work_week || 'Work Week',
    day: messages.day || 'Day',
    agenda: messages.agenda || 'Agenda',
  };

  return (
    <div className="rbc-toolbar">
      {/* Navigation Buttons */}
      <div className="rbc-btn-group">
        <button type="button" onClick={() => onNavigate('PREV')}>
          {messages.previous || 'Back'} {/* Translated Previous */}
        </button>
        <button type="button" onClick={() => onNavigate('TODAY')}>
          {messages.today || 'Today'} {/* Translated Today */}
        </button>
        <button type="button" onClick={() => onNavigate('NEXT')}>
          {messages.next || 'Next'} {/* Translated Next */}
        </button>
      </div>

      {/* Date Picker in place of the label */}
      <div className="rbc-toolbar-label">
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="MMMM d, yyyy"
          customInput={<button>{label}</button>}
          popperPlacement="bottom-start"
          popperProps={{ strategy: 'fixed' }}
          wrapperClassName="datepicker-wrapper"
          // Higher z-index for visibility
          popperClassName="custom-datepicker-popper"
        />
      </div>

      {/* View Switcher */}
      <div className="rbc-btn-group">
        {Object.keys(availableViews).map((viewName) => (
          <button
            key={viewName}
            type="button"
            onClick={() => onView(viewName)}
            className={view === viewName ? 'rbc-active' : ''}
          >
            {availableViews[viewName]}
          </button>
        ))}
      </div>
    </div>
  );
}

export default CustomToolbar;
