import styled from 'styled-components';

export const Container = styled.div`
  max-width: 200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  font-family: 'Segoe UI', sans-serif;
  transition: all 0.3s ease-in-out;

  h3 {
    margin-bottom: 20px;
    font-size: 1.6em;
    color: #343a40;
    text-align: center;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      margin-left: 10px;
      transition: transform 0.3s ease;
    }

    &:hover svg {
      transform: scale(1.2);
    }
  }

  @media (max-width: 768px) {
    padding: 15px;
    h3 {
      font-size: 1.4em;
    }
  }
`;

export const EmployeeList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: all 0.3s ease-in-out;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    color: black;

    &:hover {
      background-color: #e9ecef;
      transform: scale(1.02);
    }

    &:focus-within {
      outline: 2px solid #007bff;
      background-color: #f1f1f1;
    }

    span {
      font-size: 0.7em;
      font-weight: 600;
      color: black;
      transition: color 0.3s ease;
    }

    svg {
      cursor: pointer;
      font-size: 1em;
      color: #495057;
      transition: color 0.3s ease, transform 0.3s ease;
    }

    &:hover svg {
      color: #943534;
      transform: scale(1.2);
    }
  }

  @media (max-width: 768px) {
    li {
      flex-direction: column; /* Stack content vertically */
      align-items: flex-start; /* Align items to start */
      padding: 10px; /* Provide more padding for touch targets */
      text-align: left; /* Align text to the left */

      span {
        font-size: 0.9em; /* Slightly larger font for readability on small screens */
        margin-bottom: 8px; /* Space between span and icon */
      
      }

      svg {
        align-self: flex-end; /* Align icon to the end for better spacing */
      }
    }
  }

  @media (max-width: 480px) {
    li {
      padding: 2px 2px; /* Increase padding for smaller devices */
      gap: 5px; /* Reduce gap between items */
      
      span {
        font-size: 0.5em; /* Make sure text is readable */
        margin-bottom: 6px; /* Adjust margin */
      }

      svg {
        font-size: 1em; /* Increase icon size for better touchability */
      }
    }
  }
`;
