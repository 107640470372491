import React, { createContext, useState, useEffect, useContext } from 'react';
import AuthContext from './authContext';
import API from './axioSetup';
export const EmployeesContext = createContext();
export const EventsContext = createContext();

export const EmployeesProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
 
  
  const userId = user ? user.username : null;
  const [employees, setEmployees] = useState([]);
  const [employeeIds, setEmployeeIds] = useState([]);
 console.log('HHIIHIHIHIIHHHIIHIH');
 
  useEffect(() => {
    if (!userId) return;

    const fetchEmployees = async () => {
      try {
        const response = await API.get(`/getMitarbeiterByUserId?userId=${userId}`);
        setEmployees(response.data);
        const ids = response.data.map((emp) => emp.mitarbeiter_id);
        setEmployeeIds(ids);
      } catch (error) {
        console.error('Fehler beim Abrufen der Mitarbeiter:', error);
      }
    };

    fetchEmployees();
  }, [userId]);

  return (
    <EmployeesContext.Provider value={{ employees, employeeIds, setEmployees }}>
      {children}
    </EmployeesContext.Provider>
  );
};

export const EventsProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const userId = user ? user.username : null;
  const [allEvents, setAllEvents] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const fetchEventsAndUpdate = async () => {
    if (!userId) return;
    
    try {
      const response = await API.get(`/showevents?userId=${userId}`);
  
      const eventsData = response.data;
  
  
      const formattedEvents = eventsData.map((event) => ({
        ...event,
        start_datetime: new Date(event.start_datetime),
        end_datetime: new Date(event.end_datetime),
      }));
  
      const isGradient = (color) => color.startsWith("linear-gradient");
  
      const filteredEvents = formattedEvents.filter((event) => {
        if (!isGradient(event.color)) {
          return !event.employee_names.some((employee) =>
            filteredEmployees.includes(employee.id)
          );
        }
        return true;
      });
  
      setAllEvents(filteredEvents);
    } catch (error) {
      console.error('Fehler beim Abrufen der Events:', error);
    }
  };

  useEffect(() => {
   
      fetchEventsAndUpdate(); // Initial fetch
      console.log('Aktualisieren Akzeptiert');
      

      const intervalId = setInterval(fetchEventsAndUpdate, 10 * 60 * 1000); // 5-minute interval

      return () => clearInterval(intervalId); // Cleanup on unmount

  }, [user, filteredEmployees, userId]);

  return (
    <EventsContext.Provider
      value={{
        allEvents,
        setAllEvents,
        fetchEventsAndUpdate,
        filteredEmployees,
        setFilteredEmployees,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};
